/* === Text Mixins === */

@mixin text-uppercase {
    text-transform: uppercase;
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal $padding-vertical;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// quadratic images
@mixin quadratic-image($max-size: 100%) {
    padding-top: 100%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        max-width: $max-size;
        max-height: $max-size;
    }
}

// transforms
@mixin transform($transform) {
    -webkit-transform: $transform;
    -ms-transform: $transform; // IE9 only
    -o-transform: $transform;
    transform: $transform;
}

@mixin translate-rotate($x, $y, $degrees) {
    -webkit-transform: translate($x, $y) rotate($degrees);
    -ms-transform: translate($x, $y) rotate($degrees); // IE9 only
    -o-transform: translate($x, $y) rotate($degrees);
    transform: translate($x, $y) rotate($degrees);
}

// browser support flexbox
@mixin flex-parent($flex-flow: row wrap, $align-items: stretch, $justify-content: space-between) {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */

    -webkit-flex-flow: $flex-flow;
    -moz-flex-flow: $flex-flow;
    -ms-flex-flow: $flex-flow;
    flex-flow: $flex-flow;

    -webkit-align-items: $align-items;
    -moz-align-items: $align-items;
    -ms-align-items: $align-items;
    align-items: $align-items;

    -webkit-justify-content: $justify-content;
    -moz-justify-content: $justify-content;
    -ms-justify-content: $justify-content;
    justify-content: $justify-content;
}

@mixin flex-child() {

}

// Icon
@mixin icon-fill($fill: #ffffff) {
    fill: $fill;
}

@mixin icon($size, $fill: null) {
    @if $size == tiny {
        width: 10px;
        height: 10px;
    } @else if $size == small {
        width: 16px;
        height: 16px;
    } @else {
        width: $size;
        height: $size;
    }
    @if $fill {
        @include icon-fill($fill);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

@mixin background-color($background-color: $white, $selector: white) {
    background-color: $background-color;
    &.bg-#{$selector}--transparent {
        background-color: rgba($background-color, 0.9);
    }
}

/* === BEM Mixins === */

/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin element($element, $more: null) {
    #{bemEls($element, $more)} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier, $more: null) {
    #{bemMods($modifier, $more)} {
        @content;
    }
}

/// @alias element
@mixin e($element, $more: null) {
    @include element($element, $more)  {
        @content;
    }
}

/// @alias modifier
@mixin m($modifier, $more: null) {
    @include modifier($modifier, $more) {
        @content;
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function bemEl($name) {
    @return '&__#{$name}';
}

@function bemEls($firstName, $moreNames: null) {
    $namesStr: '#{bemEl($firstName)}';

    @if $moreNames {
        @each $name in $moreNames {
            $namesStr: "#{$namesStr}, #{bemEl($name)}";
        }
    }

    @return $namesStr;
}

@function bemMod($name) {
    @return '&--#{$name}';
}

@function bemMods($firstName, $moreNames: null) {
    $namesStr: '#{bemMod($firstName)}';

    @if $moreNames {
        @each $name in $moreNames {
            $namesStr: "#{$namesStr}, #{bemMod($name)}";
        }
    }

    @return $namesStr;
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

// no import from shared mixins because it's too different compared to the other brands
@mixin hamburger-static () {
    .hamburger {
        font: inherit;
        overflow: visible;
        margin: 0;
        padding: 15px;
        cursor: pointer;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity,filter,-webkit-filter;
        text-transform: none;
        color: inherit;
        border: 0;
        background-color: transparent;
        @include media-breakpoint-down(sm) {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .hamburger-box {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
    }

    .hamburger--collapse .hamburger-inner {
        top: auto;
        bottom: 0;
        transition-delay: .13s;
        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        transition-duration: .13s;
    }

    .hamburger--collapse .hamburger-inner::before {
        transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19),-webkit-transform .13s cubic-bezier(.55,.055,.675,.19);
    }

    .hamburger--collapse .hamburger-inner::after {
        top: -20px;
        transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity .1s linear;
    }

    // hamburger color

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform,-webkit-transform;
        border-radius: 4px;
        background-color: #000;
    }

    .hamburger-inner {
        display: block;
        margin-top: -2px;
    }

    .hamburger-inner::before, .hamburger-inner::after {
        content: '';
        display: block;
    }

    .hamburger-inner:before {
        top: -10px;
    }

    .hamburger-inner:after {
        top: -20px;
        bottom: -10px;
    }
}

@mixin hamburger-sticky () {
    .hamburger--collapse.is-active {
        .hamburger-inner {
            transform: translate3d(0, -10px, 0) rotate(-45deg);
            transition-delay: .22s;
            transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        }
    }

    .hamburger--collapse.is-active {
        .hamburger-inner::before {
            top: 0;
            transform: rotate(-90deg);
            transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s,-webkit-transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        }
    }

    .hamburger--collapse.is-active {
        .hamburger-inner::after {
            top: 0;
            opacity: 0;
            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
        }
    }
}

@mixin mmenu-styles () {

    .header {
        .mmenu__language-switch .language {
            line-height: 40px;

        }
    }

    .mm-menu {
        --mm-color-background: #ddd;
    }

    .navigation.mm-listitem__text {
        font-weight: 300;
    }

    .mmenu__footer {
        display :block !important;
        padding-top: 5px;
        padding-bottom: 20px;
    }

    .mmenu__language-switch {
        background-color: #ddd;
        padding: 0 20px;
        .language {
            line-height: 70px;
            width: 100%;
            .language-container {
                display: inline-block;
                a {
                    color: $blue-dark-01;
                }
            }
            .divisor {
                display: none;
            }
            .currentLanguage {
                position: relative;
                color: $red;
                padding-right: 18px;
                &:after {
                    margin-right: 5px;
                    position: absolute;
                    top: 50%;
                    @include translate(0, -50%);
                    right: 0;
                    content: '';
                    width: 2px;
                    height: 40px;
                    background-color: $blue-dark-01;

                }
            }
        }

    }
}