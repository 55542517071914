// font sizes flow mixin
@mixin flow-font-size($flow-variable, $flow-index: auto){
    $flow-length: length($flow-variable);

    @if $flow-index == "auto" {
        @include flow-font-size($flow-variable, 1);

        @include media-breakpoint-up(md) { @include flow-font-size($flow-variable, 2); }
        @include media-breakpoint-up(lg) { @include flow-font-size($flow-variable, 3); }
        @include media-breakpoint-up(xl) { @include flow-font-size($flow-variable, 4); }
    } @else if $flow-index >= $flow-length {
        font-size: nth($flow-variable, $flow-length);
    } @else {
        font-size: nth($flow-variable, $flow-index);
    }
}

@mixin grey-skyline-background {
    background: $color-white-grey url("../img/skyline.png") no-repeat center bottom;
    background-size: contain;
    position: relative;
    padding-bottom: $brick-padding + 225px;
    @include media-breakpoint-down(md) {
        padding-bottom: $brick-padding;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
    }
}

@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio); // IE9 only
    -o-transform: scale($ratio);
    transform: scale($ratio);
}

// global hamburger mixins
@mixin hamburger-static() {
    .hamburger {
        padding: ($default-space / 2) $default-space;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    .hamburger--collapse .is-active {
        .hamburger-inner {
            transform: translate3d(0,-10px,0) rotate(-45deg);
            transition-delay: .22s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
        }
    }

    .hamburger--collapse .is-active {
        .hamburger-inner::before {
            top: 0;
            transform: rotate(-90deg);
            transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1);
        }
    }

    .hamburger--collapse .is-active {
        .hamburger-inner::after {
            top: 0;
            opacity: 0;
            transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s .22s linear;
        }
    }
;

    .hamburger-box {
        width: 40px;
        height: 24px;
        display: inline-block;
        position: relative;
    }

    .hamburger--collapse .hamburger-inner {
        top: auto;
        bottom: 0;
    }

    // hamburger color
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        width: 40px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        background-color: black;
        @include transition(background-color 500ms);
    }

    .hamburger-inner::before {
        content: '';
        top: -10px;
        transition: top .12s .2s cubic-bezier(.33333,.66667,.66667,1),transform .13s cubic-bezier(.55,.055,.675,.19);
    }

    .hamburger-inner::after {
        content: '';
        top: -20px;
        transition: top .2s .2s cubic-bezier(.33333,.66667,.66667,1),opacity .1s linear;
    }
}

@mixin hamburger-sticky() {
    &.sticky, &.always-sticky {
        .hamburger {
            @include media-breakpoint-down(sm) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .hamburger--collapse.is-active {
            .hamburger-inner {
                transform: translate3d(0, -10px, 0) rotate(-45deg);
                transition-delay: .22s;
                transition-timing-function: cubic-bezier(.215, .61, .355, 1);
            }
        }

        .hamburger--collapse.is-active {
            .hamburger-inner::before {
                top: 0;
                transform: rotate(-90deg);
                transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
            }
        }

        .hamburger--collapse.is-active {
            .hamburger-inner::after {
                top: 0;
                opacity: 0;
                transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
            }
        }

        .hamburger-inner:before {
            content: '';
            top: -10px
        }

        .hamburger-inner:after {
            content: '';
            top: -20px;
            bottom: -10px;
        }
    }
}
