@import "bootstrap";
$name: '.footer';

#{$name} {
    font-family: $font-family-base;

    &__top {
        order: 2;
        background-color: $blue-dark-01;
        padding: 60px 0 !important;
        #{$name}__logo-list--headline * {
            margin-bottom: 35px;
            font-family: $font-family-base;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 40px;
            line-height: 58px;
            color: #fff;
        }
        #{$name}__logo-list--wrapper {
            display: flex;
            flex-direction: row;
            gap: 30px;
            justify-content: space-evenly !important;
            & * { justify-content: space-between !important; }
        }
        #{$name}__logo-list--img {
            padding: 0;
            justify-content: flex-start !important;
            margin: 0 !important;
            &.col-sm-1 {
                width: 25%;
            }
        }

        #{$name}__logo-list--link {
            padding: 0;
            width: 25%;
        }
    }
    &__main {
        order: 1;
        background-color: $blue-dark-01;
        padding-top: 60px !important;
        * { color: $white; }
        #{$name}__columns {
            gap: 30px;
            #{$name}__column {
                &-two, &-three, &-four {
                    width: 25%;
                    #{$name}__column-headline {
                        font-size: 22px;
                        font-weight: bold;
                        margin-bottom: 24px;
                        text-transform: uppercase;
                    }
                    #{$name}__column-link {
                        text-decoration: none;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
                &-one {
                    width: 25%;
                    #{$name}__column-headline {
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1;
                        text-transform: uppercase;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
            }
            #{$name}__contact-data > * {
                padding-top: 36px;
                font-size: 12px;
            }
        }
    }
    &__bottom {
        order: 3;
        * {
            color: $blue-dark-01;
            font-size: 12px;
        }
        &-legal-links {
            align-self: flex-start !important;
        }
        a { text-decoration: none }
    }
}