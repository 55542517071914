@mixin make-font($size: null, $weight: null, $family: $font-family-base, $line-height: null, $color: null) {
    @if $color {
        color: $color
    }
    @if $size {
        @include flow-font-size($size)
    }
    @if $weight {
        font-weight: $weight
    }
    @if $family {
        font-family: $family
    }
    @if $line-height {
        line-height: $line-height
    }
    margin: 0;
}

@mixin teaser-top-headline() {
    font-family: $font-family-base;
    font-size: $font-size-20px;
    font-weight: $font-weight-regular;
    text-transform: uppercase;

    @include media-breakpoint-between(md,lg) {
        font-size: $font-size-20px * 0.9;
    }

    @include media-breakpoint-between(sm,md) {
        font-size: $font-size-20px * 0.8;
    }

    @include media-breakpoint-down(sm) {
        font-size: $font-size-20px * 0.7;
    }
}

.teaser-top-headline {
    @include teaser-top-headline;
}

@mixin teaser-image-headline() {
    font-family: $font-family-base;
    font-size: $font-size-36px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    line-height: 1.2;

    @include media-breakpoint-between(md,lg) {
        font-size: $font-size-36px * 0.9;
    }

    @include media-breakpoint-between(sm,md) {
        font-size: $font-size-36px * 0.8;
    }

    @include media-breakpoint-down(sm) {
        font-size: $font-size-36px * 0.7;
    }

    @media (max-width: 380px) {
        font-size: $font-size-36px * 0.6;
    }
}


.teaser-image-headline {
    @include teaser-image-headline;
}

$font-size-stage-h1: 1.5rem 2.125rem 2.125rem 3.25rem 3.25rem;
@mixin stage-h1 {
    @include make-font($font-size-stage-h1, $font-weight-bold, $font-family-base, 1.2, $blue);
    text-transform: uppercase;
}

$font-size-stage-subHeadline: 1.5rem 1.5rem 1.5rem 2rem 2rem;
@mixin stage-subHeadline {
    @include make-font($font-size-stage-subHeadline, $font-weight-bold, $font-family-base, 1.2, $blue);
    text-transform: uppercase;
}

@mixin footer-links {
    @include make-font(0.9375rem, $font-weight-light, $font-family-base, 1.2, $white);
}


@mixin footer-main-link {
    @include make-font(1rem, $font-weight-bold, $font-family-base, 1.5, $white);
    text-transform: uppercase;
}

@mixin sub-footer {
    @include make-font(0.8125rem, $font-weight-light, $font-family-base, 1.5, $white);
}

$font-size-font-h1: 1.5rem 2.125rem 2.125rem 3.25rem 3.25rem;
@mixin font-h1 {
    @include make-font($font-size-font-h1, $font-weight-bold, $font-family-base, 1.2, $grey-darker);
}

h1, .h1 {
    @include font-h1;
}


$font-size-font-h2: 1.9rem 2.45rem 2.45rem 3rem 3rem;
@mixin font-h2 {
    @include make-font($font-size-font-h2, $font-weight-bold, $font-family-base, 1.2, $grey-darker);
}

h2, .h2 {
    @include font-h2;
}


$font-size-font-h3: 1.7rem 1.7rem 2rem 2rem 2rem;
@mixin font-h3 {
    @include make-font($font-size-font-h3, $font-weight-semibold, $font-family-base, 1.2, $blue);
    text-transform: uppercase;
    margin-bottom: 15px;
}

h3, .h3 {
    @include font-h3;
}


$font-size-font-h4: 1.15rem 1.3rem 1.3rem 1.5rem 1.5rem;
@mixin font-h4 {
    @include make-font($font-size-font-h4, $font-weight-semibold, $font-family-base, 1.2, $grey-darker);
}

h4, .h4 {
    @include font-h4;
}

@mixin font-button-m{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-16px;
}

@mixin font-copy-m{
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    font-size: $font-size-17px;
    line-height: $line-height-22px;
}

@mixin font-copy-s{
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    font-size: $font-size-15px;
    line-height: $line-height-22px;
}

@mixin font-faq{
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    font-size: $font-size-16px;
    line-height: $line-height-24px;
}

@mixin font-h1{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-40px;
    line-height: $line-height-58px;
}

@mixin font-h2{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-25px;
    line-height: $line-height-34px;
}

@mixin font-h3{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-22px;
}

@mixin font-h4{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-18px;
    line-height: $line-height-20px;
}

@mixin font-h5{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-16px;
    line-height: $line-height-24px;
}

@mixin font-header-text-l{
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    font-size: $font-size-30px;
    line-height: $line-height-40px;
}

@mixin font-navigation{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-16px;
}

@mixin font-price{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-50px;
    line-height: $line-height-58px;
}

@mixin font-textlink-s{
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-16px;
}