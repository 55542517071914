/* Hide CMD Branding Logo of Consent Banner */
/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
    display: none;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}


.cookieconsent-optout-overlay {

    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    flex-direction: column;


    &__headline {
        text-align: center;
        color: $white;
        margin-bottom: 15px;
        font-size: 1.75em;
        font-weight: bold;
    }

    &__text {
        text-align: center;
        color: $white;
        margin-bottom: 15px;
        font-size: 1.25em;
    }

    &__button {
        color: $white;
        background-color: $blue;
        border-color: $white;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 0;
    }

}
