// @todo bootstrap variable settings
// @see assets/integration/node_modules/bootstrap/scss/_variables.scss

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1140px
);

// set grid gutter to 15px
$grid-gutter-width: 1.875rem;

/* === Fonts === */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

$font-size-15px: 15px;
$font-size-16px: 16px;
$font-size-17px: 17px;
$font-size-18px: 18px;
$font-size-22px: 22px;
$font-size-25px: 25px;
$font-size-30px: 30px;
$font-size-40px: 40px;
$font-size-45px: 45px;
$font-size-50px: 50px;

$line-height-20px: 20px;
$line-height-22px: 22px;
$line-height-24px: 24px;
$line-height-34px: 34px;
$line-height-40px: 40px;
$line-height-58px: 58px;
$line-height-60px: 60px;