.b-event-teaser {
    padding: $brick-padding;

    .brick-anchor {
        display: inline-flex;
        gap: 10px;
    }

    .card-header.row{
        margin: 0;
    }

    color: $blue-dark-01;
    margin-top: 80px;

    @include media-breakpoint-down(lg) {
        margin-top: 32px;
    }

    &__headline {
        margin-bottom: 60px;
        text-align: center;
        font-size: $font-size-40px;
        font-weight: bold;

        @include media-breakpoint-down(lg) {
            margin-bottom: 32px;
        }
    }

    .event-table {

        @include media-breakpoint-up(lg) {
            margin-top: 24px;
        }

        &__title {
            font-size: $font-size-32px;
            line-height: $line-height-40px;
            text-transform: uppercase;

            @include media-breakpoint-down(lg) {
                margin-bottom: 8px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 28px;
            }
        }

        &__row {
            margin: 24px 0 24px;
            padding-bottom: 24px;

            &:not(:last-child) {
                border-bottom: $gray-200 1px solid;
            }

            &__item {
                display: flex;
                align-items: center;

                &__image {
                    @include media-breakpoint-down(lg) {
                        display:none;
                    }
                }
            }
        }

        &:not(.show-all) {
            .event-table__row {
                @include media-breakpoint-up(md) {
                    &:nth-child(3) {
                        border-bottom: 0;
                    }
                }

                &:nth-child(n+4) {
                    display: none;
                }
            }
        }

        .date {
            color: $grey-2;
            font-weight: bold;
            font-size: 12px;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }

        .description {
            font-weight: $font-weight-light;
            line-height: $line-height-20px;
        }

    }

    &__button-wrapper {
        @include media-breakpoint-up(md) {
            .btn:first-child:not(:last-child) {
                margin-right: 30px;
            }
        }

        @include media-breakpoint-down(md) {
            padding-top: 24px;

            .btn {
                margin-top: 2px;
            }
        }
    }

    &__load-more-button {
        display: block;
        margin: 80px auto 30px;

        @include media-breakpoint-down(lg) {
            border: none;
        }

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
    }

}