$name: '.b-wysiwyg';
#{$name} {
    &__wrapper {
        #{$name}__headline {
            font-family: $font-family-base;
            font-weight: 700;
            color: $blue-dark-02;
            margin-bottom: 15px;
            font-size: 40px;
            line-height: 58px;
        }
        #{$name}__text {
            color: $blue-dark-01;
        }
    }
    .brick-anchor {
        display: inline-flex;
        gap: 10px;
    }
    .card-header.row{
        margin: 0;
    }
}