@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/fontawesome-fonts/fa-brands-400.eot');
    src: url('../fonts/fontawesome-fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fontawesome-fonts/fa-brands-400.woff2') format('woff2'),
    url('../fonts/fontawesome-fonts/fa-brands-400.woff') format('woff'),
    url('../fonts/fontawesome-fonts/fa-brands-400.ttf') format('truetype'),
    url('../fonts/fontawesome-fonts/fa-brands-400.svg#fontawesome') format('svg');
}