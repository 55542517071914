.b-accordion{
    padding: $brick-padding;

    &__headline {
        text-align: center !important;
        color: $primary;
    }

    .brick-anchor {
        display: inline-flex;
        gap: 10px;
    }

    .card-header.row{
        margin: 0;
    }
}

.accordion{
    border-top: 1px solid $gray-200;

    &__headline {
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        border-top: 1px solid rgba($primary, 0.2);
        padding-top: 1rem;
        line-height: 2.5rem;
        cursor: pointer;
        font-size: $font-size-18px;
        color: $grey-darkest;
        font-weight: $font-weight-bold;

        &.collapsed {
            font-weight: $font-weight-light;
        }

        &:hover {
            text-decoration: none;
        }
        &.collapsed{
            .accordion__icon {
                &--down {
                    display: none;
                }
                &--up {
                    display: inline-block;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding-right: 40px;
            line-height: 1.5;
        }

        @include media-breakpoint-down(xs) {
            font-size: $font-size-18px * 0.9;
            line-height: 1.3;
        }
    }
    &__icon {
        font-size: 1.5rem;
        margin: 1px 15px 0 0;

        &--down {
            display: inline-block;
        }

        &--up {
            display: none;
        }

        .icon-minus_m {
            svg {
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                text-align: center;
            }
        }

        .icon-plus_m {
            svg {
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                text-align: center;
            }
        }
    }
    &__content {
        color: $grey-darkest;
        font-weight: $font-weight-light;

        p {
            margin-bottom: 1rem;
        }
    }

    &__item {
        &:first-child .accordion__headline {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 1px solid rgba($primary, 0.2);
        }
    }

    &__block {
        &:not(:first-child) {
            margin-top: 30px;
        }

        &-headline {
            .h1 {
                color: $blue-dark-01;
            }

            font-size: $font-size-24px;
            color: $grey-dark;
            text-align: center;
            font-weight: $font-weight-semibold;
            padding-bottom: 1rem;
        }

        &-text {
            color: $blue-dark-01;
            text-align: center;
            width: 80%;
            margin: 0 auto 75px auto;
        }
    }
}
