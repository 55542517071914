.stage {
    max-width: $max-width;
    margin: 0 auto;
    padding: 26px 28px;
    position: relative;
    &__image {
        img {
            margin-left: auto;
        }
    }
    &__callout {
        picture {
            position: absolute;
            right: 0;
            bottom: -140px;
            z-index: 1;
        }
    }
    &__top {
        margin-bottom: 140px;
    }
    &__logo {
        display: inline-block;
    }
    &__tradefair-logo {
        display: inline-block;
        margin-left: 70px;
    }
    &__tradefair-shuttle-logo {
        max-width: 402px;
        margin-bottom: 60px;
    }
    &__text {
        margin-bottom: 90px;
    }
    &__inner {
        position: absolute;
        top: 50%;
        @include translate(0, -50%);
        left: 200px;
    }
    &__content {
        height: $stage-image-height;
        overflow: hidden;
        position: relative;
        &.float-end {
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    &__overlay {
        img {
            height: $stage-image-height;
        }
        top: 0;
        left: -168px;
        position: absolute;
    }
    &__top-callout {
        position: absolute;
        display: block;
        top: 120px;
        left: 50%;
        @include translate(-50%, 0);
    }

    &__xs-images {
        position: relative;
    }

    @media (max-width: ($breakpoint-lg-1638)) {
        &__top-callout {
            @include scale(0.7);
            left: 42%;
            top: 80px;
        }
        &__inner {
            left: 100px;
        }
        &__callout {
            picture {
                @include scale(0.8);
                right: -185px;
                bottom: -185px;
            }
        }
        &__overlay {
            left: -130px;
        }
    }

    @include media-breakpoint-down(xl) {
        &__top-callout {
            @include scale(0.7);
            left: 55%;
            top: 80px;
        }
        &__overlay {
            left: -30px;
        }
        &__callout {
            picture {
                right: -280px;
                @include scale(0.7);
                bottom: -235px;
            }
        }
    }


    @include media-breakpoint-down(md) {
        &__text {
            margin-bottom: 40px;
        }
        &__inner {
            position: relative;
            top: 0;
            @include translate(0, 0);
            left: 0;
        }
        &__top-callout {
            picture {
                right: 0;
                top: 30px;
                left: 45%;
                @include scale(0.4);
            }
        }

        &__content {
            margin: 55px 0;
            &.float-start {
                height: 100%;
            }
        }
    }
    @include media-breakpoint-down(sm){
        &__top {
            margin-bottom: 40px;
        }
        &__top-callout {
            right: auto;
            left: -120px;
            top: -130px;
            @include scale(0.3);
        }
        &__callout {
            picture {
                right: -280px;
                @include scale(0.5);
                bottom: -250px;
            }
        }
    }
}