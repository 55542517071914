.b-price{
    @include e('inner'){
        position: relative;
        padding: $brick-padding;
    }
}

.b-price {

    .price-frontend {
        text-align: center;

        .b-price__headline {
            text-align: center;
        }

        .b-price__text {
            text-align: center;
        }
    }

    .brick-anchor {
        display: inline-flex;
        gap: 10px;
    }

    .card-header.row{
        margin: 0;
    }

    @include e('image') {
        position: absolute;
        left: 0;
        top: 0;
    }
    @include e('comparison') {
        text-align: center;
        margin: 60px 0 45px;
    }
    @include e('comparison-inner') {
        position: relative;
        min-width: 200px;
        display: inline-block;
        text-align: center;
    }
    @include e('comparison-line') {
        display: inline-block;
        margin: 0 30px;
        position: relative;
        &:after {
            position: absolute;
            bottom: -25px;
            content: "";
            display: block;
            width: 1px;
            height: 130px;
            background-color: $white;
        }
    }
    @include e('comparison-content') {
        display: inline-block;
        text-align: center;
    }


    @media (max-width: ($breakpoint-lg-1720)) {
        @include e('image') {
            top: 180px;
        }
    }


    @media (max-width: $screen-sm-max) {
        @include e('comparison-inner') {
            min-width: 100%;
            display: block;
            text-align: center;
            margin-bottom: 30px;
            &.last {
                margin-bottom: 0;
            }
        }
        @include e('comparison-content') {
            text-align: center;
        }
    }
}
