// styling for headings selection in tinymce
.tox-collection__item-label {
    h1, h2, h3, h4, h5, h6 {
        line-height: normal !important;
    }
}

.brick-anchor {
    display: inline-flex;
    gap: 10px;
}