@import "bootstrap";
$name: '.footer';

// general footer styles for all brands
footer {
    display: flex;
    flex-direction: column;

    // top footer
    #{$name}__top {
        padding: 15px 0;
        #{$name}__logo-list {
            &--img {
                margin: 0 auto;
            }
            &--wrapper {
                display: flex;
                justify-content: center;
                > * {
                    display: flex;
                    justify-content: center;
                }
            }
            &--headline {
                &-centered {
                    text-align: center;
                }
            }
        }
    }

    // main footer
    #{$name}__main {
        padding: 30px 0;
        &--headline {
            &-centered {
                text-align: center;
            }
        }
        #{$name}__columns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
            &--centered {
                text-align: center;
                @include media-breakpoint-down(md) {
                    #{$name}__column {
                        &-one, &-two, &-three, &-four {
                            align-self: center;
                        }
                    }
                }
            }
            #{$name}__column-text, #{$name}__column-link{
                &--bold {
                    font-weight: bold;
                }
                &--spacing-bottom {
                    margin-bottom: 20px;
                }
            }
        }
        #{$name}__column {
            &-one, &-two, &-three, &-four {
                align-self: flex-start;
                @include media-breakpoint-down(md) {
                    padding-top: 15px;
                }
            }
            &-links {
                display: flex;
                flex-direction: column;
            }
            &-images {
                display: flex;
                @include media-breakpoint-between(md, lg) {
                    flex-direction: column;
                }
            }
        }
    }

    // bottom footer
    #{$name}__bottom {
        padding: 30px 0;
        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                gap: 20px;
            }
        }
        &-copyright, &-social-links, &-cta, &-legal-links {
            align-self: center;
        }
        &-copyright {
            display: flex;
            flex-direction: column;
        }
        &-social-links {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
        &-social-link {
            text-decoration: none;
            position: relative;
            color: #fff;
            font-size: 1.5em;
            font-family: $fontfamily-fontawesome-brands;
        }
        &-social-icon {
            font-style: normal;
            &--instagram {
                &:before {
                    content: "\f16d"
                }
            }
            &--twitter {
                &:before {
                    content: "\f081"
                }
            }
            &--linkedin {
                &:before {
                    content: "\f08c"
                }
            }
            &--xing {
                &:before {
                    content: "\f168"
                }
            }
            &--facebook {
                &:before {
                    content: "\f082";
                }
            }
            &--facebook-f {
                &:before {
                    content: "\f39e";
                }
            }
        }
        &-divider {
            padding: 0 5px;
        }
    }
}