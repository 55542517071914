$name: '.b-advantage';
#{$name} {
    padding: $brick-padding;

    &__headline {
        font-family: $font-family-base;
        font-weight: 700;
        color: $blue-dark-02;
        text-align: center;
        margin-bottom: 70px;
        font-size: 40px;
        line-height: 58px;
    }
    &__block {
        width: 100%;
        &-outer {
            display: flex;
        }
        &-inner {
            position: relative;
            text-align: center;
            float: none;
            width: 100%;
            @include media-breakpoint-up(md) {
                margin-right: 0;
                width: 25%;
                display: block;
            }
        }
        &-image {
            display: block;
            max-width: 100%;
            height: auto;
            vertical-align: middle;
            > img {
                width: 100%;
                height: 100%;
            }
        }
        &-shadow {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(180deg,rgba(21,28,50,.75) 0,rgba(21,28,50,.75));
        }
        &-headline {
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            transform: translate(-50%,-50%);
            color: #fff;
            width: 85%;
            z-index: 50;
            font-size: 25px;
            line-height: 34px;
            font-family: $font-family-base;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    .carousel-control {
        &-prev, &-next {
            display: block;
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .brick-anchor {
        display: inline-flex;
        gap: 10px;
    }

    .card-header.row{
        margin: 0;
    }
}