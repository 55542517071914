.interferer {

    &__wrapper {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 17rem;
        z-index:200;
        @include media-breakpoint-down(md){
            top: initial;
            bottom: 0;
            position: fixed;
        }
    }

    position:absolute;
    color: $white;
    right: 5rem;
    width: 40%;
    @media (min-width: 1200px ){
        width: 30%;
    }
    @include media-breakpoint-down(md){
        width:100%;
        position:initial;
    }
    padding: 3rem 3rem 2rem;
    text-align: center;
    &__title {
        h1,h2,h3,h4,h5{
            color: $white;
            font-weight: bold;
        }
    }
    &__iconwrapper {
        position: absolute;
        top: -49px;
        width: 100px;
        height: 50px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border-top-left-radius: 102px;
        border-top-right-radius: 102px;
        z-index: 1;
    }

    &__icon{
        width: 50px;
        margin: 0 auto;
        margin-top: 20px;
    }

    &__closingbutton{
        top: 1.5rem;
        right: 2rem;
        position: absolute;
        cursor: pointer;
        &-icon{
            font-size: 28px;
        }
    }


    &__text {

    }

    &__button {
        &:hover{
            color:white;
        }
        i,svg{
            margin-left: 5px;
        }
        display: inline-block;

        color: $white;
        padding: 10px 40px;
        border: 2px solid $white;
        text-decoration: none;
        font-family: HelveticaBold, Fallback, sans-serif;
        font-size: .9rem;
        background-size: 19px auto;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 10px;
    }
}



