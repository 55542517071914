header {
    @include hamburger-static;
    @include hamburger-sticky;
    @include mmenu-styles;

    left: 50%;
    transform: translateX(-50%);

    .header__language-switch {
        background-color: $white;
        width: 115px;
        height: 70px;
        border: 1px solid $gray-200;
        box-shadow: 0 0 20px -8px $black;
        float: right;
        margin-left: 15px;
        padding: 0 20px;
        .language {
            line-height: 70px;
            width: 100%;
            .language-container {
                display: inline-block;
                a {
                    color: $blue-dark-01;
                }
            }
            .divisor {
                display: none;
            }
            .currentLanguage {
                position: relative;
                color: $red;
                padding-right: 18px;
                &:after {
                    position: absolute;
                    top: 50%;
                    @include translate(0, -50%);
                    right: 0;
                    content: '';
                    width: 2px;
                    height: 40px;
                    background-color: $blue-dark-01;

                }
            }
            .changeLanguage {
                float: right;
            }
        }

        @include media-breakpoint-down(sm) {
            height: 50px;
            width: 90px;
            padding: 0 10px;
            .language {
                line-height: 50px;
                .currentLanguage {
                    padding-right: 15px;
                    &:after {
                        height: 30px;
                    }
                }
            }
        }
    }

    .header__inner {
        position: absolute;
        top: 50px;
        right: 28px;
        height: 70px;

        @include media-breakpoint-down(sm) {
                top: 0;
                right: 0;
        }
    }

    .header__link-list {
        float: right;
        padding: 0;
        margin: 0;
        height: 100%;
        background-color: $white;
        border: 1px solid $gray-200;
        box-shadow: 0 0 20px -8px $black;
    }

    .header__link-item {
        height: 100%;
        padding: 0 30px;
        line-height: 70px;
        display: inline-block;
        list-style: none;
        a {
            color: $blue-dark-02;
        }
    }

    .header__mobile-button {
        position: absolute;
        right: 0;
        top: 50px;
        background-color: rgba($white,1);
        z-index: 1;
        &:active,
        &:focus{
            outline: none;
        }
    }

    display: block;
    z-index: 1000;
    position: absolute;
    max-width: $max-width;
    width: 100%;
    &.sticky {
        position: fixed;
        .header__inner {

            top: 0;
        }
        .header__mobile-button{
            top: 0 ;
            @include box-shadow(0px 0px 20px -8px $black);
        }
    }

    .hidden {
        display: none;
    }

}

#mobile-nav {
    z-index: 100;
    background-color: $gray-200;
    --mm-color-background: $gray-200;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block
    }

    &.mm-menu {
        display: flex;
    }

    .mmenu__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        line-height: 40px;
        padding: 12px 0;

        .mmenu__language-switch {
            .language {
                display: flex;
                flex-direction: row;
                div:first-child {
                    position: relative;
                    padding-right: 18px;
                    margin-right: 18px;
                    &:after {
                        position: absolute;
                        top: 50%;
                        @include translate(0, -50%);
                        right: 0;
                        content: '';
                        width: 2px;
                        height: 40px;
                        background-color: $blue-dark-01;

                    }
                }
                .currentLanguage {
                    color: $red;
                }
                .changeLanguage {
                    color: $blue-dark-01;
                }
            }
        }

        .mmenu__contact-details {
            width: 100%;
        }
    }

    .icon-mail_m {
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
    }

    .icon-phone_m {
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
    }
}
