/**
 * Bootstrap Overrides
 */
$white: #ffffff;
$green: #51AD32;
$black: #000000;
$blue-light: #82CEEE;
$blue-dark-01: #151C32;
$blue-dark-02: #002147;
$gray-100: #868686;
$gray-200: #E1E1E1;
$gray-300: #c6c6c6;
$red: #E62F55;

/*
  only put custom variables here,
  change values of standard bootstrap variables in bootstrap's variables.less
*/

$imagepath: "/website/static/img";
$fontpath: "/website/static/dist/fonts";
$icon-font-path: "/website/static/dist/vendor/bootstrap-sass-3.3.7/assets/fonts/bootstrap/";

/* === Colors === */
$white: #FFFFFF;
$black: #000000;
$red: #D30032;
$white-grey: #F1F1F1;
$grey-light: #E5E5E5;
$grey: #DADADA;
$grey-2: #151C3274;
$grey-dark: #494949;
$grey-darker: #3C3C3B;
$grey-darkest: #333333;
$grey-blue: #B2C3D5;
$orange: #F9B21F;
$ice-blue: #E0E6EE;
$blue: #003975;
$blue-light: #82CEEE;
$blue-darker: #133264;
$green: #51AD32;
$mint: #17BDA5;
$body-color: $grey-darker;
$text-color: $grey-darker;

$primary: $blue;
$secondary: $orange;

/* === Fonts === */
//$font-family-base:  'Montserrat', sans-serif;
$font-family-base: 'Titillium Web', sans-serif;
$fontfamily-fontawesome-brands: 'Font Awesome 5 Brands';
$fontfamily-fontawesome: 'Font Awesome 6 Pro';

/* === font sizes === */
$font-size-12px: 0.75rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-24px: 1.5rem;
$font-size-32px: 2rem;
$font-size-36px: 2.25rem;
$font-size-15px: 15px;
$font-size-16px: 16px;
$font-size-17px: 17px;
$font-size-18px: 18px;
$font-size-22px: 22px;
$font-size-25px: 25px;
$font-size-30px: 30px;
$font-size-32px: 32px;
$font-size-40px: 40px;
$font-size-50px: 50px;

$line-height-20px: 20px;
$line-height-22px: 22px;
$line-height-24px: 24px;
$line-height-34px: 34px;
$line-height-40px: 40px;
$line-height-58px: 58px;


$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$screen-xs-max: 575px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 767px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 991px;

$stage-image-height: 918px;
$max-width: 1920px;
$brick-padding: 55px 0;

/* === BreakPoints === */
$breakpoint-lg-1638: 1638px;
$breakpoint-lg-1720: 1720px;