.btn {
    text-transform: uppercase;
    font-size: $font-size-12px;
    padding: 9px 39px;
    border-radius: 0;
    font-weight: $font-weight-bold;

    &--primary {
        color: $white;
        border: 1px solid $red;
        background-color: $red;

        @include media-breakpoint-down(sm) {
            padding: 9px 29px;
        }

        &:hover {
            background-color: transparent;
            border-color: $red;
            font-weight: $font-weight-bold;
            color: $red;
        }
    }

    &--secondary {
        color: $blue-dark-01;
        background-color: transparent;
        border: 1px solid $blue-dark-01;

        @include media-breakpoint-down(sm) {
            padding: 9px 29px;
        }

        &:hover {
            background-color: $blue-dark-01;
            font-weight: $font-weight-bold;
            color: $white;
        }
    }

    &--link-double-arrow {
        color: $blue-dark-01;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        @include media-breakpoint-down(md) {
            border: 1px solid $blue-dark-01;
        }

        &:before {
            content: "\f101";
            padding-right: 3px;
            font-family: $fontfamily-fontawesome;
            font-size: 8px;
        }

        &:hover {
            @include media-breakpoint-down(md) {
                background-color: $blue-dark-01;
                color: $white;
            }
        }
    }

    /**
     * @TODO OLD BUTTON STYLE CONFIGURATION - DELETE IF NOT NEEDED!
     */
    &-primary {
        border-radius: 0;
        background-color: $orange;
        text-transform: uppercase;
        color: $white;
        border: none;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 17px 25px;

        &:hover {
            background-color: $primary;
            @include transition(all ease-in-out 300ms);
        }
    }
    &-blue {
        border-radius: 0;
        background-color: $blue;
        text-transform: uppercase;
        color: $white;
        border: none;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 15px 25px;

        &:hover {
            background-color: $orange;
            @include transition(all ease-in-out 300ms);
        }
    }

    &-red, &-secondary {
        border-radius: 0;
        background-color: $red;
        text-transform: uppercase;
        color: $white;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 15px 25px;
        border: 2px solid $white;

        &:hover {
            background-color: $white;
            color: $red;
            @include transition(all ease-in-out 300ms);
            border: 2px solid $red;
        }
    }

    &-link {
        font-weight: $font-weight-bold;
        color: $primary;
        padding-left: 25px;
        text-decoration: none !important;
        background: url("../../img/long-arrow-alt-right-blue.svg") no-repeat center left;
        background-size: 20px 20px;
        border: none;
        @include transition(all ease-in-out 300ms);

        &:hover {
            color: $orange;
            background: url("../../img/long-arrow-alt-right-orange.svg") no-repeat center left;
            background-size: 20px 20px;
            @include transition(all ease-in-out 300ms);
        }
    }
}

.color-white {
    color: $white;
}

.color-blue {
    color: $blue;
}

.color-grey {
    color: $white-grey;
}

.text-color {
    color: $grey-dark;
}

.copy-m {
    @include font-copy-m();
    color: $white;
}

.copy-s {
    @include font-copy-s();
    color: $blue-dark-01;
}

.faq {
    @include font-faq();
}

h1, .h1 {
    @include font-h1();
    color: $white;
}

h2, .h2 {
    @include font-h2();
}

h3, .h3 {
    @include font-h3();
    color: $blue-dark-02;
}

h4, .h4 {
    @include font-h4();
    color: $blue-dark-01;
}

h5, .h5 {
    @include font-h5();
    color: $white;
}

.header-text-l {
    color: $blue-dark-01;
    @include font-header-text-l();
}

.navigation {
    @include font-navigation();
}

.price {
    @include font-price();
    color: $white;
}

.textlink-s {
    @include font-textlink-s();
}

.background-blue {
    background-color: $blue-light;
}

.link-style {
    text-transform: uppercase;
    display: inline-block;
    @include font-button-m();
    color: $white;
    background-color: $red;
    height: 70px;
    line-height: 70px;
    padding: 0 45px;
    margin: 1px 2px;
    &:hover{
        text-decoration: none;
        color: $white;
    }
    &:focus{
        color: $white;
    }

    @media (max-width: $screen-sm-min) {
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
    }
}

.link-style-white{
    text-transform: uppercase;
    display: inline-block;
    @include font-button-m();
    background-color: $white;
    color: $blue-dark-01;
    border: 1px solid $blue-dark-01;
    padding: 28px 38px;
    &:hover{
        text-decoration: none;
        color: $blue-dark-01;
    }
}

.link-style--black{
    text-transform: uppercase;
    display: inline-block;
    @include font-button-m();
    color: $white;
    background-color: $black;
    height: 70px;
    line-height: 70px;
    padding: 0 45px;
    margin: 1px 2px;
    &:hover{
        text-decoration: none;
        color: $white;
    }
    &:focus{
        color: $white;
    }

    @media (max-width: $screen-sm-min) {
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
    }
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5{
    margin-top: 0;
    margin-bottom: 10px;
}

.mark-red{
    @include font-copy-m();
    color: $red;
    font-weight: $font-weight-bold;
}
.hamburger{
    &:hover{
        opacity: 1 !important;
    }
}
.mark-blue{
    @include font-copy-m();
    color: $blue-light;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}

a{
    color: rgb(35,82,124);
    text-decoration: none;
    &:focus,
    &:hover{
        color: #23527c;
    }
}

.mm-slideout{
    z-index: auto;
}